body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.back-image{
	background-image: url('./assets/cfo.jpg');
}

.back-image-bottom{
	background-image: url('./assets/work.png');
}

.t-center{
	text-align: center;
}

.t-left{
	text-align: left;
}

.t-right{
	text-align: right;
}

.no-margin{
	margin: 0px !important
}

.flex{
	display: flex;
}

.a-center{
	align-items: center;
}

.j-center{
	justify-content:  center;
}